export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'distributors',
    label: 'Distributors',
  },
  {
    path: 'workshops',
    label: 'Workshops',
  },
  {
    path: 'features',
    label: 'Features',
  },
  {
    path: 'contact',
    label: 'Contact',
  },
];
